
import throttle from 'lodash.throttle'
import { defineComponent, onBeforeMount, onBeforeUnmount, ref } from 'vue'

import AddToCartButton from './AddToCartButton.vue'

export default defineComponent({
  name: 'AddToCartStickyHeader',
  components: {
    AddToCartButton,
  },
  props: {
    sku: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    price: {
      type: String,
      required: false,
      default: '',
    },
    buttonText: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    const shouldShow = ref<boolean>(false)

    const handleScroll = (): void => {
      
      let yaxis = 600 //desired "show" point for mobile (aka small screen) users

      if (window.innerWidth > 768) {
        yaxis = 300 //desired "show" point for desktop (aka large screen) users
      }

      if (window.scrollY > yaxis) {
        shouldShow.value = true
      } else {
        shouldShow.value = false
      }
    }

    onBeforeMount((): void => {
      window.addEventListener('scroll', throttle(handleScroll, 500, { leading: true }))
    })

    onBeforeUnmount((): void => {
      window.removeEventListener('scroll', handleScroll)
    })

    return {
      shouldShow,
      handleScroll,
    }
  },
})
