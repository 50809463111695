import axios, { AxiosResponse } from 'axios'

import gql from '../../utils/gql'
import getStoreName from '../../utils/getStoreName'
import CustomerDetails from '../types/CustomerDetails'

interface CreateCustomerResponse {
  data: {
    ReliasMediaCreateCustomerInput: {
      customer: {
        firstname: string
        lastname: string
        email: string
        password: string
        date_of_birth: string
        media_fax: string
        media_verified: number
        media_job_title: string
        media_phone_extension: string
        media_npi_number: string
        media_abim_membership: string
        media_abim_member_id: string
        media_abms_board: string
        media_secondary_board_id: string
        media_credentials: string
        media_credential_secondary: string
        media_specialty: string
        media_profession: string
      } | null
    }
  }
  errors?: [{ message: string }]
}

// Note: Password confirmation is required, but Magento
// doesn't have a field for it, so it must be validated
// on the frontend.
export const createCustomerMutation = gql`
  mutation(
    $prefix: String
    $firstName: String!
    $middleName: String
    $lastName: String!
    $email: String!
    $password: String!
    $birthdate: String
    $npiNumber: String,
    $abimMembers: String,
    $abimMemberId: String,
    $abmsBoard: String,
    $abmsBoardId: String,
    $primaryCredentials: String,
    $secondaryCredentials: String,
    $specialty: String,
    $profession: String,
    $phoneExtension: String,
    $jobTitle: String,
    $fax: String,
    $mediaVerified: Int
  ) {
    reliasMediaCreateCustomer(
      input: {
        prefix: $prefix
        firstname: $firstName
        middlename: $middleName
        lastname: $lastName
        email: $email
        password: $password
        date_of_birth: $birthdate
        media_fax: $fax
        media_verified: $mediaVerified
        media_job_title: $jobTitle
        media_phone_extension: $phoneExtension
        media_npi_number: $npiNumber
        media_abim_membership: $abimMembers
        media_abim_member_id: $abimMemberId
        media_abms_board: $abmsBoard
        media_secondary_board_id: $abmsBoardId
        media_credentials: $primaryCredentials
        media_credential_secondary: $secondaryCredentials
        media_specialty: $specialty
        media_profession: $profession
      }
    ) {
      customer {
        firstname
        lastname
        email
      }
    }
  }
`

/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const createCustomer = async (customerDetails: CustomerDetails): Promise<void> => {
  const { data }: AxiosResponse<CreateCustomerResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers: {
      'Content-Type': 'application/json',
      Store: getStoreName(),
    },
    data: {
      query: createCustomerMutation,
      variables: {
        prefix: customerDetails.prefix,
        firstName: customerDetails.firstName,
        middleName: customerDetails.middleName,
        lastName: customerDetails.lastName,
        email: customerDetails.email,
        password: customerDetails.password,
        birthdate: customerDetails.birthdate,
        npiNumber: customerDetails.npiNumber,
        abimMembers: customerDetails.abimMembers,
        abimMemberId: customerDetails.abimMemberId,
        abmsBoard: customerDetails.abmsBoard,
        abmsBoardId: customerDetails.abmsBoardId,
        primaryCredentials: customerDetails.primaryCredentials,
        secondaryCredentials: customerDetails.secondaryCredentials,
        specialty: customerDetails.specialty,
        profession: customerDetails.profession,
        phoneExtension: customerDetails.phoneExtension,
        jobTitle: customerDetails.jobTitle,
        fax: customerDetails.fax,
        mediaVerified: customerDetails.mediaReviewed
      },
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }
}
