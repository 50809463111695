
import {defineComponent, onMounted, ref, computed, watch, onUnmounted} from 'vue'
import useLoader from '../../composables/useLoader'
import Client from '@searchkit/instantsearch-client'
import Searchkit, { type SearchkitConfig } from 'searchkit'
import SearchItemCard from '../search/SearchItemCard.vue'
import SearchFilters from '../search/SearchFilters.vue'

import {
  AisConfigure,
  AisInfiniteHits,
  AisInstantSearch,
  AisSearchBox,
  AisSortBy,
  AisStats,
  AisClearRefinements,
  AisCurrentRefinements,
} from 'vue-instantsearch/vue3/es'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import { simple as simpleStateMapping } from 'instantsearch.js/es/lib/stateMappings'
import DateRange from '../search/DateRange.vue'

export default defineComponent({
  name: 'SearchForm',
  components: {
    DateRange,
    FontAwesomeIcon,
    SearchFilters,
    SearchItemCard,
    AisInstantSearch,
    AisStats,
    AisInfiniteHits,
    AisSearchBox,
    AisSortBy,
    AisConfigure,
    AisClearRefinements,
    AisCurrentRefinements,
  },
  setup() {
    const { setLoading } = useLoader()

    const indexName = process.env.ELASTICSEARCH_RM_INDEX ?? ''

    //Used to make sure only live entries are ever pulled in
    const statusFilter = 'status:live'

    // Reactive state to store the query
    const query = ref('')

    

    const searchkitClient = new Searchkit({
      connection: {
        cloud_id: process.env.ELASTICSEARCH_CLOUD_ID,
        auth: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          username: process.env.ELASTICSEARCH_USER!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          password:process.env.ELASTICSEARCH_USER_PASS!,
        },
      },
      search_settings: {
        highlight_attributes: [],
        search_attributes: [
          { field: 'name.keyword', weight: 10 },
          { field: 'article_keywords.keyword', weight: 2 },
          'description',
        ],
        result_attributes: [
          'name',
          'entry_type_label',
          'post_date',
          'parent_label',
          'parent_link',
          'uri',
        ],
        facet_attributes: [
          { attribute: 'entry_type', field: 'entry_type.keyword', type: 'string' },
          { attribute: 'is_free', field: 'is_free', type: 'string' },
          { attribute: 'blog_title', field: 'blog_title.keyword', type: 'string' },
          { attribute: 'book_title', field: 'book_title.keyword', type: 'string' },
          { attribute: 'new_article', field: 'new_article', type: 'string' },
          { attribute: 'newsletter_level_one', field: 'newsletter_level_one.keyword', type: 'string' },
          { attribute: 'newsletter_level_two', field: 'newsletter_level_two.keyword', type: 'string' },
          { attribute: 'related_topics', field: 'related_topics.keyword', type: 'string' },
        ],
        filter_attributes: [
          { attribute: 'status', field: 'status', type: 'string' },
          { attribute: 'post_date_filter', field: 'post_date_filter', type: 'numeric' },
        ],
        sorting: {
          default: [
            query.value ? { field: '_score', order: 'desc' } : { field: 'post_date', order: 'desc' },
          ],
          _relevance_desc: {
            field: '_score',
            order: 'desc',
          },
          _relevance_asc: {
            field: '_score',
            order: 'asc',
          },
          _post_date_asc: [
            { field: 'post_date', order: 'asc' },
            { field: '_score', order: 'desc' },
          ],
          _post_date_desc: [
            { field: 'post_date', order: 'desc' },
            { field: '_score', order: 'desc' },
          ],
          _featured: [
            { field: 'post_date', order: 'desc' },
            { field: '_score', order: 'desc' },
          ],
        },
        query_rules: [],
      },
    })

    

    const searchClient = Client(searchkitClient, {
      getQuery: (query) => {
        // This const is created b/c ElasticSearch cannot read the value of search_attributes above
        const elasticSearchAttributeFields = [
          'name.keyword^10',
          'article_keywords.keyword^2',
          'description',
        ]
        return {
          bool: {
            should: [
              {
                bool: {
                  should: [
                    {
                      multi_match: {
                        fields: elasticSearchAttributeFields,
                        fuzziness: 'AUTO:4,8',
                        query: query,
                        type: 'best_fields',
                        operator: 'AND',
                      },
                    },
                    {
                      multi_match: {
                        fields: elasticSearchAttributeFields,
                        query: query,
                        type: 'best_fields',
                        operator: 'AND',
                      },
                    },
                  ],
                },
              },
              {
                multi_match: {
                  fields: elasticSearchAttributeFields,
                  query: query,
                  type: 'best_fields',
                  operator: 'AND',
                },
              },
            ],
          },
        }
      },
    })

    //Routing is needed to make sure query params work correctly
    const routing = {
      stateMapping: simpleStateMapping(),
    }

    const filterableAttributes = [
      { title: 'Type', code: 'entry_type', type: 'ais-refinement-list' },
      { title: 'Free', code: 'is_free', type: 'ais-toggle-refinement'},
      { title: 'Blog Title', code: 'blog_title', type: 'ais-refinement-list' },
      { title: 'Book Title', code: 'book_title', type: 'ais-refinement-list' },
      { title: 'News Article', code: 'new_article', type: 'ais-toggle-refinement' },
      { title: 'Newsletter Categories', code: 'newsletter_categories', type: 'ais-hierarchical-menu' },
      { title: 'Related Topics', code: 'related_topics', type: 'ais-refinement-list' },
    ]

    //Prefix the ais-sort-by items with environment variable: https://github.com/searchkit/searchkit/issues/1338
    const sortByItemPrefix = ():Record<string, string>[] => {
      return [
        { value: indexName + '_featured', label: 'Featured'},
        { value: indexName + '_post_date_desc', label: 'Newest to Oldest' },
        { value: indexName + '_post_date_asc', label: 'Oldest to Newest' },
        { value: indexName + '_relevance_desc', label: 'Relevance - DESC' },
        { value: indexName + '_relevance_asc', label: 'Relevance - ASC' },
      ]
    }

    /* Search Page Title */
    

    // Computed property for the header title
    const headerTitle = computed(() => {
      return query.value ? `Showing Results for "${query.value}"` : 'Catalog Search'
    })

    // Method to update the query
    const updateQuery = (event: Event) => {
      const target = event.target as HTMLInputElement
      query.value = target.value
    }

    // State to manage the visibility of the filters
    const filtersVisible = ref(false)

    const toggleFilters = () => {
      filtersVisible.value = !filtersVisible.value
    }

    // Count active filters
    const activeFiltersCount = ref(0)

    // Method to update the active filters count
    const updateActiveFiltersCount = (items: any[]) => {
      activeFiltersCount.value = items.reduce((total, item) => total + item.refinements.length, 0)
    }

    // Specific functions to clear custom date start/end filters
    const resetdatefilter = ref(false)
    const clearDate = () => {
      resetdatefilter.value = !resetdatefilter.value
    }

    watch(resetdatefilter, (newVal) => {
      if (newVal) {
        // Delay to ensure child component has processed the change
        setTimeout(() => {
          resetdatefilter.value = false
        }, 0)
      }
    })


    // Infinite Scroll
    const scrollEvents = () => {
      let scrollTimer: number

      window.addEventListener('scroll', function () {
        if (scrollTimer !== null) {
          clearTimeout(scrollTimer)
        }
        scrollTimer = window.setTimeout(function () {
          loadMoreItems()
        }, 100)
      }, false)
    }
    const isElementVisible = (e: HTMLElement) => {
      if (e !== null) {
        let visible = false,
          rect = e.getBoundingClientRect(),
          viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)

        if (!(rect.bottom < 0 || rect.top - viewHeight >= 0)) {
          visible = true
        }

        return visible
      } else {
        throw console.error('Invalid selector passed to isElementVisible()')
      }
    }
    const loadMoreItems = () => {
      const showMoreButton = document.getElementById('ais-infinite-load-more')

      if (showMoreButton != null && isElementVisible(showMoreButton)) {
        showMoreButton.click()
      }
    }

    onMounted(() => {
      scrollEvents()
    })

    return {
      searchClient,
      filterableAttributes,
      sortByItemPrefix,
      indexName,
      query,
      headerTitle,
      updateQuery,
      toggleFilters,
      filtersVisible,
      routing,
      statusFilter,
      activeFiltersCount,
      updateActiveFiltersCount,
      clearDate,
      resetdatefilter,
    }
  },
})
