
import {defineComponent, onMounted, ref, unref} from 'vue'

import useCart from '../../composables/useCart'
import useNotify from '../../composables/useNotify'
import { addBundledProductsToCart, addProductsToCart } from '../../../../global/js/magento-api-library/cart/addProductsToCart'
import { trackAddProductToCart } from '../../../../global/js/analytics/gtm'
import { getSingleProduct } from '../../../../global/js/magento-api-library/products/getSingleProduct'
import { log } from '../../../../global/js/utils/logging'
import useLoader from '../../composables/useLoader'

export default defineComponent({
  name: 'AddToCartButton',
  props: {
    productSku: {
      type: String,
      required: true,
    },
    buttonClasses: {
      type: String,
      required: false,
      default: null,
    },
    buttonId: {
      type: String,
      required: false,
      default: null,
    },
    buttonText: {
      type: String,
      required: false,
      default: 'Add to Cart',
    },
    redirectUrl: {
      type: String,
      required: false,
      default: null,
    },
    inCartText: {
      type: String,
      required: false,
      default: 'In Cart',
    },
  },
  setup(props) {
    const { notify } = useNotify()
    const { cart, getCart, setMinicartCount } = useCart()
    const isLoading = ref<boolean>(true)
    const { setLoading } = useLoader()
    const loadingButtonText = ref<string>('Loading')
    const isInCart = ref<boolean>(false)

    setLoading(true)

    const updateMinicartCount = ():void => {
      const cartDataRaw = localStorage.getItem('cart')

      if(cartDataRaw !== null) {
        const cartData = JSON.parse(cartDataRaw)
        setMinicartCount(cartData.total_quantity)
      }
    }

    const handleClick = async (): Promise<void> => {
      try {
        let unrefCart = unref(cart)
        loadingButtonText.value= 'Adding'
        isLoading.value = true

        const product = await getSingleProduct(props.productSku)

        if(product[0].__typename === 'BundleProduct') {
          let uid : Array<string> = []
          product[0].items.forEach((singleItem) => {
            singleItem.options.forEach((option) => {
              uid.push(option.uid)
            })
          })
          const newCart = await addBundledProductsToCart(unrefCart.id, [{sku: props.productSku, quantity: 1,
            selected_options: uid }])
          trackAddProductToCart(newCart?.items.find((item) => { return item.product.sku == props.productSku}), 1)
        }
        else {
          const newCart = await addProductsToCart(unrefCart.id, [{ sku: props.productSku, quantity: 1 }])
          trackAddProductToCart(newCart?.items.find((item) => { return item.product.sku == props.productSku}), 1)
        }

        if (props.redirectUrl) {
          window.location.assign(props.redirectUrl)
        } else {
          updateMinicartCount()
          isInCart.value = true
          notify({
            type: 'success',
            title: 'Success!',
            text: 'Product was added to your cart.',
          })
        }
      } catch (error) {
        let errorMessage = ''
        if(error instanceof Error){
          errorMessage = error.message
        } else {
          errorMessage = error as string
        }
        let notificationText = ''
        if(errorMessage.includes('The requested qty exceeds the maximum qty allowed in shopping cart')){
          if (props.redirectUrl) {
            window.location.assign(props.redirectUrl)
          } else {
            notificationText = 'That product is already in your cart.'
          }
        } else {
          notificationText = 'Product could not be added to your cart.'
        }

        log(error as string, 'src/reliasmedia/js/components/products/AddToCartButton.vue')

        notify({
          type: 'error',
          title: 'Error',
          text: notificationText,
          duration: -1,
        })

      } finally {
        isLoading.value = false
      }
    }

    onMounted(async (): Promise<void> => {
      let unrefCart = unref(cart)

      if (!unrefCart.id) {
        await getCart()
        unrefCart = unref(cart)
      }

      if (unrefCart.items && unrefCart.items.find(item => item.product.sku === props.productSku)) {
        isInCart.value = true
      }

      isLoading.value = false
      setLoading(false)
    })

    return {
      isLoading,
      loadingButtonText,
      handleClick,
      isInCart,
    }
  },
})
