import { computed, ComputedRef } from 'vue'

import { useStore } from '../store/index'
import { logout } from '../../../global/js/magento-api-library/auth/logout'
import Customer from '../../../global/js/magento-api-library/types/Customer'
import { CustomerMutationTypes } from '../store/modules/customer/mutation-types'
import { getCustomer } from '../../../global/js/magento-api-library/customer/getCustomer'
import log from '../../../global/js/utils/logging'

interface UseCustomer {
  customer: ComputedRef<Customer | null>
  setCustomer: () => Promise<void>
}

const useCustomer = (): UseCustomer => {
  const store = useStore()
  const customer = computed((): Customer | null => store.state.customer?.customer)

  const setCustomer = async (): Promise<void> => {
    try {
      const customer = await getCustomer()

      if (customer) {
        store.commit(CustomerMutationTypes.SET_CUSTOMER, customer)
        const customerData = store.state.customer?.customer
        localStorage.setItem('customer', JSON.stringify(customerData))
      }
    } catch (error) {
      log(error as string, 'src/reliasmedia/js/composables/useCustomer.ts')
      await logout()
    }
  }

  return {
    customer,
    setCustomer: async () => {
      clearLocalStorage()
      await setCustomer()
    },
  }
}

const clearLocalStorage = (): void => {
  localStorage.removeItem('customer')
}


export default useCustomer
