import {resetCart} from '../../utils/cartHandler'
import {clear} from 'idb-keyval'

export const logout = async (): Promise<void> => {
  localStorage.removeItem('authToken')
  localStorage.removeItem('transcripts')
  localStorage.removeItem('customer')
  await clear()
  resetCart()
}
