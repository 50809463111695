import SubscribeNow from './SubscribeNow.vue'
import AddToCartButton from './AddToCartButton.vue'
import AddToCartStickyHeader from './AddToCartStickyHeader.vue'
import ProductsCategoryDropdown from './ProductsCategoryDropdown.vue'
import ProductsCategoryDropdownItem from './ProductsCategoryDropdownItem.vue'
import ProductsSort from './ProductsSort.vue'

const productsComponents = [
  SubscribeNow,
  AddToCartButton,
  AddToCartStickyHeader,
  ProductsCategoryDropdown,
  ProductsCategoryDropdownItem,
  ProductsSort,
]

export default productsComponents
